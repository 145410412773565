import puja1 from '../../images/puja1.webp'
import puja2 from '../../images/puja2.webp'
import puja3 from '../../images/puja3.webp'
import puja4 from '../../images/puja4.webp'
import puja5 from '../../images/puja5.webp'
import puja6 from '../../images/puja6.webp'
import puja7 from '../../images/puja7.webp'
import puja8 from '../../images/puja8.webp'
import puja9 from '../../images/puja9.jpg'
import puja10 from '../../images/Pujapatri.jpg'
import puja11 from '../../images/puja11.jpg'
import Imagecards from '../Imagecards';

const Services = () => {
    return ( 
        <div className="service-body">
            <div className="puja-img-body">
                <div className="puja-img-row-one">
                    <Imagecards img={puja1} text="Ganesh Sthapana"/>
                    <Imagecards img={puja2} text="Satyanarayan Pooja"/>
                    <Imagecards img={puja3} text="Vastu Shanti"/>
                    <Imagecards img={puja4} text="Vivah"/>
                    <Imagecards img={puja10} text="Janm Patri"/>
                    <Imagecards img={puja11} text="Marwari Puja"/>
                </div>
                <div className="puja-img-row-two">
                    <Imagecards img={puja5} text="Grah Pravesh"/>
                    <Imagecards img={puja6} text="Vrat"/>
                    <Imagecards img={puja7} text="Shradh"/>
                    <Imagecards img={puja8} text="Graha Yagna"/>
                    <Imagecards img={puja9} text="Astrology"/>
                </div>
            </div>
        </div>
     );
}
 
export default Services;