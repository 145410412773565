import punditimg from '../../images/punditimg.webp'
import './About.css'

const About = () => {
    return ( 
        <div className="about-body">
            <img src={punditimg} alt="" />
            <h2>ACHARYA KESHAV JI</h2>
            <p>
                            Being a North Indian Pandit in bangalore shastri Ji 
                            serves exact and customized religious needs with pooja 
                            samagri at a reasonable Dakshina. Services offered by 
                            Acharya Keshav Ji specializes in conducting religious 
                            rituals like Naming ceremony, Vastu shanti,Marwari puja, Janm Patrika, Jyotish / Astrology, New Office,pravesh, 
                            Birthday ceremony puja, Mundan ceremony, Thread wearing ceremony, 
                            SatyaNarayan Pooja and Griha Pravesh in and around Bangalore.
            </p>
            <p>
                        Acharya Keshav Ji is also famous as a Bihari Pandit in Bangalore . 
                        He provide all kind of Puja and anushthan services according to Bihar 
                        culture He conducts different religious ceremonies at your place.he provides 
                        one stop solutions for all types of puja with puja samagri.if You are 
                        looking to book online Bihari pandit in bangalore you can do it directly 
                        through this website. He is a well known pandit in Bangalore.
            </p>
        </div>
     );
}
 
export default About;