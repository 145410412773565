import Carouselcomp from '../Carouselcomp';
import carousel1 from '../../images/carousel1.webp'
import carousel2 from '../../images/carousel2.webp'
import carousel3 from '../../images/carousel3.webp'


const Gallery = () => {
    return ( 
        <div className="gallery-body">
            <Carouselcomp img1={carousel1} img2={carousel2} img3={carousel3} />
        </div>
     );
}
 
export default Gallery;